//  -yzm

//other
let __system = {
  __activeid: "",
  __menu: [
  {
    pageFlag: false,
    id: "syc",
    title: "数据同步",
    icon: "el-icon-menu",
    children: [
    // {
    //   pageFlag: false,
    //   id: "sycbase",
    //   title: "基础设置",
    //   icon: "el-icon-menu",
    //   children: [
    //   {
    //     pageFlag: true,
    //     id: "account",
    //     title: "数据库设置",
    //     name: "account",
    //     icon: "el-icon-document",
    //     component: "views/saiz/Account.vue",
    //     visible:true

    //   },
    //   {
    //     pageFlag: true,
    //     id: "type",
    //     title: "同步类型设置",
    //     name: "type",
    //     icon: "el-icon-document",
    //     component: "views/saiz/Type.vue"
    //     ,visible:true
    //   },
    //   {
    //     pageFlag: true,
    //     id: "detail",
    //     title: "同步明细设置",
    //     name: "detail",
    //     icon: "el-icon-document",
    //     component: "views/saiz/Detail.vue"
    //     ,visible:true
    //   }]
    // },
    {
        pageFlag: false,
        id: "syccommon",
        title: "基础资料同步",
        icon: "el-icon-menu",
        children: [
        {
          pageFlag: true,
          id: "inventoryclass",
          title: "存货分类同步",
          name: "inventoryclass",
          icon: "el-icon-document",
          component: "views/saiz/InventoryClass.vue"
          ,visible:true
        },{
          pageFlag: true,
          id: "inventory",
          title: "存货同步",
          name: "inventory",
          icon: "el-icon-document",
          component: "views/saiz/Inventory.vue"
          ,visible:true
        },{
          pageFlag: true,
          id: "bom",
          title: "物料清单同步",
          name: "bom",
          icon: "el-icon-document",
          component: "views/saiz/Bom.vue"
          ,visible:true
        }
        /**,
        {
          pageFlag: true,
          id: "saledeliveryb",
          title: "销货单同步",
          name: "saledeliveryb",
          icon: "el-icon-document",
          component: "views/saiz/SaleDeliveryb.vue"
          ,visible:true
        },
        // {
        //   pageFlag: true,
        //   id: "puorderexec",
        //   title: "采购订单下单",
        //   name: "puorderexec",
        //   icon: "el-icon-document",
        //   component: "views/saiz/Puorderexec.vue"
        //   ,visible:false
        // },
        {
          pageFlag: true,
          id: "SaleDelivery",
          title: "销货单生成采购入库单",
          name: "SaleDelivery",
          icon: "el-icon-document",
          component: "views/saiz/SaleDelivery.vue"
          ,visible:true
        }*/
      ],
      },
      {
        pageFlag: false,
        id: "sycsale",
        title: "日常业务同步生单",
        icon: "el-icon-menu",
        children: [
          {
            pageFlag: true,
            id: "SaleOrder",
            title: "销售订单生成采购订单(代发)",
            name: "SaleOrder",
            icon: "el-icon-document",
            component: "views/saiz/SaleOrder.vue"
            ,visible:true
          },
          {
            pageFlag: true,
            id: "PurchasesaOrder1",
            title: "采购订单生成销售订单(代发)",
            name: "PurchasesaOrder1",
            icon: "el-icon-document",
            component: "views/saiz/PurchasesaOrder1.vue"
            ,visible:true
          }, 
          {
            pageFlag: true,
            id: "PurchasesaOrder",
            title: "采购订单生成销售订单",
            name: "PurchasesaOrder",
            icon: "el-icon-document",
            component: "views/saiz/PurchasesaOrder.vue"
            ,visible:true
          },
          {
            pageFlag: true,
            id: "SaleDelivery",
            title: "销货单生成采购入库单",
            name: "SaleDelivery",
            icon: "el-icon-document",
            component: "views/saiz/SaleDelivery.vue"
            ,visible:true
          }, 
          /** 
          {
            pageFlag: true,
            id: "PurchaseOrder",
            title: "采购订单同步生成销货单",
            name: "PurchaseOrder",
            icon: "el-icon-document",
            component: "views/saiz/PurchaseOrder.vue"
            ,visible:true
          },
        {
          pageFlag: true,
          id: "SaleDelivery",
          title: "销货单生成采购入库单",
          name: "SaleDelivery",
          icon: "el-icon-document",
          component: "views/saiz/SaleDelivery.vue"
          ,visible:true
        }, 
        {
          pageFlag: true,
          id: "SaleDeliveryOrder",
          title: "销货单生成销售订单",
          name: "SaleDeliveryOrder",
          icon: "el-icon-document",
          component: "views/saiz/SaleDeliveryOrder.vue"
          ,visible:true
        },
        {
          pageFlag: true,
          id: "saledeliveryb",
          title: "销货单同步",
          name: "saledeliveryb",
          icon: "el-icon-document",
          component: "views/saiz/SaleDeliveryb.vue"
          ,visible:true
        },*/
        {
          pageFlag: true,
          id: "cgruToxhth",
          title: "采购退货生成销货单退货",
          name: "cgruToxhth",
          icon: "el-icon-document",
          component: "views/saiz/cgruToxhth.vue"
          ,visible:true
          
        },
        // {
        //   pageFlag: true,
        //   id: "saordersaOrder",
        //   title: "销售订单生成销售订单",
        //   name: "saordersaOrder",
        //   icon: "el-icon-document",
        //   component: "views/saiz/SaleOrderToSaleOrder.vue"
        //   ,visible:true
        // },
        {
          pageFlag: true,
          id: "saordersaOrder",
          title: "销售订单生成销售订单(电商通)",
          name: "saordersaOrder",
          icon: "el-icon-document",
          component: "views/saiz/SaleOrderToSaleOrder1.vue"
          ,visible:true
        },
        {
          pageFlag: true,
          id: "saletopurd",
          title: "销售订单生成采购订单(电商通)",
          name: "saletopurd",
          icon: "el-icon-document",
          component: "views/saiz/saletoorder.vue"
          ,visible:true
        }
      ],
      
      },
      {
        pageFlag: false,
        id: "sysrpt",
        title: "报表",
        icon: "el-icon-menu",
        children: [
        {
          pageFlag: true,
          id: "rpt1",
          title: "库存数据表",
          name: "rpt1",
          icon: "el-icon-document",
          component: "views/saiz/Rpt1.vue",
          visible:true
        }]},
        {
            pageFlag: false,
            id: "sycwlns",
            title: "万里牛同步",
            icon: "el-icon-menu",
            children: [
                {
                    pageFlag: true,
                    id: "wlnrdtoorder",
                    title: "出库单转订单",
                    name: "wlnrdtoorder",
                    icon: "el-icon-document",
                    component: "views/saiz/WlnRdToOrder.vue",
                    visible:true
                  }
            ]}
      
],
  }, ],
  __mdilist: [],
  set activeid(val) {
    this.__activeid = val;
  },
  get activeid() {
    return this.__activeid;
  },
  set menu(val) {
    this.__menu = val;
  },
  get menu() {
    return this.__menu;
  },
  set mdilist(val) {
    this.__mdilist = val;
  },
  get mdilist() {
    return this.__mdilist;
  }
}


let ld = (function (__system) {
  let _ld = {
    "system": __system,
  }
  return _ld;
})(__system)
export default ld